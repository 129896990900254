<template>
  <div class="app-container site-single">
    <div class="page-title">
      <h2>{{ site.url }}</h2>
    </div>
    <el-row :gutter="24">
      <el-col :span="6">
        <el-card style="margin-bottom:10px">
          <el-image
            style="width: 100%; height: auto; max-height:250px"
            :src="site.capture"
            fit="cover"
            :preview-src-list="[site.capture]"
          />
          <div style="padding: 14px;">
            <h3 style="margin:2px">{{ site.url }}</h3>
            <small>{{ site.title }}</small> <br>
            <div class="bottom clearfix">
              <small><i class="el-icon-document" /> {{ site.page_count }} {{ $t('site.pagecount') }}</small><br>
              <small><i class="el-icon-message" /> {{ site.admin_email }}</small><br>

              <el-button
                v-waves
                style="margin-top:15px"
                size="mini"
                icon="el-icon-s-promotion"
                @click="dialogNotifySiteAdmin=true"
              >
                {{ $t("site.sendNotificaction") }}
              </el-button>
            </div>
          </div>
        </el-card>
        <el-card
          v-if="checkRole(['Admin'])"
          class="box-card filter-card just-for-admin"
        >
          <div slot="header" class="clearfix">
            <span>{{ $t("general.filter") }}</span>
            <el-button
              style="float: right; padding: 3px 0"
              type="text"
              @click="initSearch"
            >{{ $t("general.init") }}</el-button>
          </div>
          <div class="filter-container">
            <el-radio
              v-model="activeList"
              label="actives"
              border
              @change="getSubSitePagesList('indexactive')"
            >{{ $t("general.F_actives") }}</el-radio>
            <el-radio
              v-model="activeList"
              label="desactives"
              border
              @change="getSubSitePagesList('indexdesactive')"
            >{{ $t("general.F_desactives") }}</el-radio>
            <el-radio
              v-model="activeList"
              label="disponibles"
              border
              @change="handleFilterSitePages"
            >{{ $t("general.disponibles") }}</el-radio>
            <el-radio
              v-model="activeList"
              label="corbeille"
              class="danger-radio"
              border
              @change="getSubSitePagesList('indexinvisible')"
            >{{ $t("general.corbeille") }}</el-radio>
          </div>
        </el-card>
      </el-col>
      <el-col :span="18">
        <el-card class="box-card">
          <div class="filter-container">
            <el-button-group>
              <el-button :type="listType=='timeline'?'primary':'secondary'" icon="el-icon-s-grid" @click="listType='timeline'" />
              <el-button :type="listType=='list'?'primary':'secondary'" icon="el-icon-s-order" @click="listType='list'" />
            </el-button-group>
            <el-input
              v-model="query.keyword"
              clearable
              :placeholder="$t('general.keyword')"
              class="wd-200 mr-15 filter-item"
              @keyup.enter.native="handleFilterSitePages"
            />
            <el-button
              v-waves
              class="mr-15 filter-item"
              type="primary"
              :icon="query.keyword!=''?'el-icon-search':'el-icon-refresh'"
              @click="handleFilterSitePages"
            >
              <span v-show="query.keyword!=''">{{ $t("general.search") }}</span>
              <span v-show="query.keyword==''">{{ $t("general.refresh") }}</span>
            </el-button>
            <el-button
              v-waves
              :loading="downloading"
              class="filter-item"
              type="primary"
              icon="el-icon-download"
              @click="handleDownload"
            >
              {{ $t("general.export") }}
            </el-button>
            <el-button v-if="checkPermission(['create site'])" class="mr-15 filter-item" type="success" icon="el-icon-plus" @click="handleCreateSite">
              {{ $t('general.add') }}
            </el-button>
          </div>
          <el-table
            v-show="listType=='list'"
            v-loading="loading"
            :data="list"
            border
            fit
            highlight-current-row
            style="width: 100%"
          >
            <el-table-column align="center" :label="$t('site.url')" min-width="300px">
              <template slot-scope="scope">
                <div class="full-width">
                  <div class="half-photo">
                    <div class="demo-basic--circle">
                      <div>
                        <el-image
                          style="width: 100px; height: 100px"
                          :src="scope.row.capture_gallery[0]"
                          fit="cover"
                          :preview-src-list="[scope.row.capture_gallery]"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="half-right">
                    <div class="in-td">
                      <h4>{{ scope.row.url }}</h4>
                      <small>{{ scope.row.title }}</small>
                    </div>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              :label="$t('site.versions')"
              width="90"
            >
              <template slot-scope="scope">
                <span>{{ scope.row.versions_count }}</span>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              :label="$t('site.lastCheck')"
              width="120"
            >
              <template slot-scope="scope">
                <span>{{ dateFormat(scope.row.last_check) }}</span>
              </template>
            </el-table-column>
            <el-table-column v-if="activeList!='corbeille' && checkPermission(['activate site'])" align="center" :label="$t('general.elligible')" width="80">
              <template slot-scope="scope">
                <el-switch
                  style="display: block"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  :active-value="true"
                  :inactive-value="false"
                  :value="scope.row.is_active"
                  :disabled="!scope.row.is_visible"
                  @change="setSiteStatus(scope.row.id,'active')"
                />
              </template>
            </el-table-column>

            <el-table-column
              v-if="
                checkRole(['Admin']) && (activeList == 'corbeille' || query.keyword)
              "
              align="center"
              :label="$t('general.restaurer')"
              width="100"
            >
              <template slot-scope="scope">
                <el-switch
                  v-if="!scope.row.is_visible"
                  style="display: block"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  :active-value="true"
                  :inactive-value="false"
                  :value="scope.row.is_visible"
                  @change="setSiteStatus(scope.row.id, 'visible')"
                />
              </template>
            </el-table-column>
            <el-table-column
              v-if="checkPermission(['edit site', 'hide site', 'delete site'])"
              align="center"
              :label="
                1 == 1 ? $t('general.actions') : $t('general.suppressionDefinitive')
              "
              width="150"
            >
              <template slot-scope="scope">
                <el-button
                  v-if="scope.row.is_visible && checkPermission(['edit site'])"
                  type="primary"
                  size="small"
                  icon="el-icon-view"
                  style="margin-right: 10px"
                  @click="handleEditSite(scope.row.id)"
                />
                <el-button
                  v-if="checkPermission(['hide site', 'delete site'])"
                  slot="reference"
                  type="danger"
                  size="small"
                  icon="el-icon-delete"
                  style="margin-right: 10px"
                  @click="
                    handleDeleteSite(
                      scope.row.id,
                      scope.row.is_visible,
                      scope.row.url
                    )
                  "
                />
              </template>
            </el-table-column>
          </el-table>
          <div v-show="listType=='timeline'" class="timeline-block">
            <div v-if="isEmptyArray(list)" class="no-selection">
              <el-empty :description="$t('site.noPage')" />
            </div>
            <el-timeline v-loading="loading">
              <el-timeline-item
                v-for="(page, index) in list"
                :key="index"
                :timestamp="page.title"
                placement="top"
              >
                <el-card :class="page.score_status+'-version-bg'">
                  <el-row :gutter="24">
                    <el-col :span="8">
                      <el-image
                        style="width: 100%; height: auto; max-height:200px"
                        :src="page.capture_gallery[0]"
                        fit="cover"
                        :preview-src-list="[page.capture_gallery]"
                      />
                    </el-col>
                    <!-- <el-col :span="2">
                      <status-indicator active|positive|intermediary|negative|pulse></status-indicator>
                      <status-indicator status="positive" pulse />
                    </el-col> -->
                    <el-col :span="12" class="page-details">
                      <a :href="page.url" class="link" target="_blank"><h4>{{ page.url }}</h4></a>
                      <p>{{ $t('site.versions') }} : {{ page.versions_count }} <status-indicator :status="page.score_status" pulse /></p>
                      <p v-show="page.last_check!=null">{{ $t('site.lastCheck') }} : {{ dateFormat(page.last_check) }}</p>
                    </el-col>
                  </el-row>
                </el-card>
              </el-timeline-item>
            </el-timeline>
          </div>

          <pagination
            v-if="total > 0"
            :total="total"
            :page.sync="query.page"
            :limit.sync="query.limit"
            @pagination="getSitePagesList"
          />

          <el-dialog
            :title="editing ? $t('site.edit') + ' ' + currentSite.url : $t('site.AddSite')"
            :visible.sync="dialogCreatePage"
            :before-close="handleClosePageCreateDraw"
            :loading="pageCreating"
            width="70%"
          >
            <div class="content-inputs">
              <el-form ref="sitePageForm" :rules="rules" :model="currentSite" label-position="left">
                <el-form-item :label="$t('site.url')" prop="url">
                  <el-input v-model="currentSite.url" />
                </el-form-item>
                <!-- <el-alert
                v-show="linkchecked"
                :title="steps.errorTitle"
                type="error"
                :description="steps.errorMessage"
                show-icon
                />>
                <el-form-item :label="$t('site.adminEmail')" prop="admin_email">
                <el-input v-model="currentSite.admin_email" />
                </el-form-item -->
              </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
              <div style="text-align:right;">
                <el-button plain type="danger" @click="dialogCreatePage=false">
                  {{ $t('permission.cancel') }}
                </el-button>
                <el-button type="primary" :loading="pageCreating" @click="editing ? updatePage() : createPage()">
                  {{ pageCreating ? $t('general.saving') : $t('general.save') }}
                </el-button>
              </div>
            </span>
          </el-dialog>

          <el-dialog
            :title="$t('site.notifyAdmin')"
            :visible.sync="dialogNotifySiteAdmin"
            width="70%"
          >
            <div class="content-inputs">
              <h3>{{ $t("login.email") }} : {{ site.admin_email }}</h3>
              <send-message :email="site.admin_email" @close="dialogNotifySiteAdmin=false" />
            </div>
          </el-dialog>

        <!-- ======= SHOW SITE DIALOG ======= -->
        </el-card>
      </el-col>
    </el-row></div>
</template>

<script>
var ext = '';
var temp = new URL(window.location.href);
ext = temp.searchParams.get('ext');
import Pagination from '@/components/Pagination'; // Pagination
import Resource from '@/api/resource';
import waves from '@/directive/waves'; // Directives
import permission from '@/directive/permission'; //  directive Permission
import role from '@/directive/role'; // Directives Role
import checkPermission from '@/utils/permission'; // Vérification Permission
import checkRole from '@/utils/role'; // Vérification Roles
import moment from 'moment';
const pageResource = new Resource('site_pages');
const siteResource = new Resource('sites');
import SendMessage from '../components/SendMessage.vue';
export default {
  name: 'SitePagesList',
  components: { Pagination, SendMessage },
  directives: { waves, permission, role },
  data() {
    return {
      listType: 'timeline',
      siteId: '',
      list: null,
      total: 0,
      loading: false,
      gettingSiteData: false,
      downloading: false,
      pageCreating: false,
      editing: false,
      activeList: 'disponibles',
      processings: this.$processingStatus,
      query: {
        page: 1,
        limit: this.$limit,
        keyword: '',
        processing: 'waiting',
      },
      currentSite: {},
      dialogCreatePage: false,
      currentSiteId: 0,
      rules: {
        url: [
          {
            required: true,
            message: this.$t('site.UrlRequired'),
            trigger: 'blur',
          },
        ],
      },
      site: {
        url: '',
        capture: '',
        title: '',
      },
      dialogNotifySiteAdmin: false,
    };
  },
  computed: {},
  created() {
    const id = this.$route.params && this.$route.params.id;
    this.siteId = id;
    this.query.site_id = id;
    if (ext === '1'){
      this.importationEcours();
    }
    this.resetNewPage();
    this.getSiteData();
    this.getSitePagesList();
  },
  methods: {
    checkPermission,
    checkRole,
    importationEcours() {
      this.$notify({
        title: 'Importation encours',
        message: "L'extraction des pages du site se fait en arrière plan et peu prendre un moment. cliquez sur le bouton *Actualiser pour voir l'évolution",
        type: 'warning',
        duration: 60000,
      });
    },
    dateFormat(created_at){
      if (created_at == null){
        return '-';
      }
      return moment(created_at).locale('fr').calendar();
    },
    isEmptyArray(array) {
      if (typeof array !== 'undefined' && array !== null && array.length === 0) {
        return true;
      }
    },
    handleClosePageCreateDraw(done) {
      this.$confirm(this.$t('general.closingWarningText'))
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    initSearch() {
      this.query = {
        page: 1,
        limit: this.$limit,
        keyword: '',
        processing: 'waiting',
      };
      this.activeList = 'disponibles';
      this.handleFilterSitePages();
    },

    async getSiteData() {
      this.gettingSiteData = true;
      const { data } = await siteResource.get(this.siteId);
      this.site = data;
      this.gettingSiteData = false;
    },

    async getSitePagesList() {
      this.loading = true;
      const { limit, page } = this.query;
      const { data, meta } = await pageResource.list(this.query);
      this.list = data;
      this.list.forEach((element, index) => {
        element['index'] = (page - 1) * limit + index + 1;
      });
      this.total = meta.total;
      this.loading = false;
    },
    async getSubSitePagesList(subUrl) {
      this.loading = true;
      const { data } = await pageResource.sublist('/' + subUrl, this.query);
      this.list = data;
      this.loading = false;
    },

    handleFilterSitePages() {
      this.query.page = 1;
      this.getSitePagesList();
    },
    handleCreateSite() {
      this.editing = false;
      this.resetNewPage();
      this.dialogCreatePage = true;
      this.$nextTick(() => {
        this.$refs['sitePageForm'].clearValidate();
      });
    },
    async setSiteStatus(id, type) {
      const data = await pageResource.get('toogle_' + type + '/' + id);
      if (data.success) {
        this.$message({
          type: 'success',
          message: this.$t('general.statusUpdated'),
        });
        this.initSearch();
      } else {
        this.$message({
          type: 'danger',
          message: this.$t('general.statusUpdateError'),
        });
      }
    },
    handleDeleteSite(id, visible, name) {
      this.$confirm(
        this.$t('site.deleteWarn') +
          ' ' +
          name +
          '. ' +
          this.$t('general.deleteContinue'),
        'Warning',
        {
          confirmButtonText: 'Yes',
          cancelButtonText: this.$t('general.cancel'),
          type: 'warning',
        }
      )
        .then(() => {
          if (
            !visible &&
            (checkRole(['Admin']) || checkPermission(['delete site']))
          ) {
            this.$swal({
              title: this.$t('general.SureQuestion'),
              text: this.$t('general.irrversibleMessage'),
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: this.$t('general.ouiSupprimer'),
            }).then((result) => {
              if (result.isConfirmed) {
                pageResource
                  .destroy(id)
                  .then((response) => {
                    this.$message({
                      type: 'success',
                      message: this.$t('site.suppressionEffectue'),
                    });
                    this.initSearch();
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }
            });
          } else {
            this.setSiteStatus(id, 'visible');
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: this.$t('general.suppressionAnnule'),
          });
        });
    },
    createPage() {
      this.$refs['sitePageForm'].validate((valid) => {
        if (valid) {
          this.pageCreating = true;
          pageResource
            .store(this.currentSite)
            .then(async(response) => {
              this.$message({
                message:
                  this.$t('site.NewSite') +
                  ' ' +
                  this.currentSite.url +
                  ' ' +
                  this.$t('site.iscreatedSuccessfully'),
                type: 'success',
                duration: 5 * 1000,
              });
              this.currentSiteId = response.data.id;
              this.resetNewPage();
              this.dialogCreatePage = false;
              this.handleFilterSitePages();
            })
            .catch((error) => {
              console.log(error);
            })
            .finally(() => {
              this.pageCreating = false;
            });
        } else {
          this.$message({
            type: 'danger',
            message: this.$t('general.invalidForm'),
          });
          return false;
        }
      });
    },
    updatePage() {
      this.$refs['sitePageForm'].validate((valid) => {
        if (valid) {
          this.pageCreating = true;
          pageResource
            .update(this.currentSite.id, this.currentSite)
            .then((response) => {
              this.resetNewPage();
              this.handleFilterSitePages();
              this.$message({
                message: this.$t('site.isupdatedSuccessfully'),
                type: 'success',
                duration: 5 * 1000,
              });
              this.dialogCreatePage = false;
              this.editing = false;
            })
            .catch((error) => {
              console.log(error);
            })
            .finally(() => {
              this.pageCreating = false;
            });
        } else {
          this.$message({
            type: 'danger',
            message: this.$t('general.invalidForm'),
          });
          return false;
        }
      });
    },
    resetNewPage() {
      this.currentSite = {
        url: '',
        site_id: this.siteId,
      };
    },
    handleDownload() {
      this.downloading = true;
      import('@/vendor/Export2Excel').then((excel) => {
        const tHeader = [
          'ID',
          this.$t('site.url'),
          this.$t('site.title'),
          this.$t('report.processing'),
        ];
        const filterVal = ['id', 'url', 'title', 'processing'];
        const data = this.formatJson(filterVal, this.list);
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename:
            'Sites list DEFACE TRACKER_' + moment().format('DD-MM-YYYY_hh-mm'),
        });
        this.downloading = false;
      });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) => filterVal.map((j) => v[j]));
    },
  },
};
</script>

<style lang="scss" scoped>
.el-card__body {
  padding: 10px !important;
}

.half-right {
  width: 100% !important;
  float: none !important;
  text-align: left !important;
  padding: 10px;
}
.edit-input {
  padding-right: 100px;
}
.cancel-btn {
  position: absolute;
  right: 15px;
  top: 10px;
}
.dialog-footer {
  text-align: left;
  padding-top: 0;
  margin-left: 150px;
}
.app-container {
  flex: 1;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
  .block {
    float: left;
    min-width: 250px;
  }
  .clear-left {
    clear: left;
  }
}
</style>
